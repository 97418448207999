import { select, selectAll, scaleLinear, drag } from "d3";
import { layoutFactorBlock } from "./layout-factor-block";
import { slider } from "./slider";
import { toggleSwitch } from "./toggle-switch";
const innerWidth = 570;
const yearScale = scaleLinear().domain([0, 100]).range([0, innerWidth]);

export function sliderSwitchingTime() {
  const { containerSelectors, defaultSetting } = this;
  const calculator = this;
  const container = select(containerSelectors.switchingTime);
  const switchProps = {
    value: ["ON", "OFF"],
    backgroundColor: ["rgba(229,135,182,0.55)", "#393a3d"],
    color: ["#e587b6", "#808080"],
    selectedIndex: 0,
  };

  //
  const { dataLabel, gInner, svg } = layoutFactorBlock(container, {
    heading: "SWITCHING TIME",
    text: "When do we switch from paying for repeated, temporary projects to paying once for permanent storage?",
    unit: "years",
    type: "slider",
  });
  //
  dataLabel.text(defaultSetting.switchingTime);

  //slider
  const gSliderNode = slider(
    gInner,
    yearScale(defaultSetting.switchingTime),
    innerWidth
  );

  if (switchProps.value[switchProps.selectedIndex] == "ON") {
    gSliderNode.call(drag().on("start", dragStart).on("drag", dragging));
  }
  // switch
  toggleSwitch(svg, switchProps);

  svg.select("g.toggle-switch").on("click", function (event) {
    switchProps.selectedIndex = switchProps.selectedIndex == 0 ? 1 : 0;
    toggleSwitch(svg, switchProps);

    if (switchProps.value[switchProps.selectedIndex] == "ON") {
      gSliderNode.call(drag().on("start", dragStart).on("drag", dragging));
      svg.select(".slider-range").attr("stroke", "#808080");

      dataLabel.attr("fill", "#e587b6");
      gSliderNode.select("circle.slider-node").attr("fill", "#E587B6");
      select("g.switch-time-cursor").attr("visibility", "visible");
      select("g.switch-time-cursor")
        .select("text")
        .attr("visibility", "visible");

      selectAll("rect.bar1").attr("fill", " rgb(107,72,93)");
    } else {
      gSliderNode.call(drag().on("start", undefined).on("drag", undefined));
      svg.select(".slider-range").attr("stroke", "rgb(57, 58, 61)");
      dataLabel.attr("fill", "rgb(57, 58, 61)");
      gSliderNode.select("circle.slider-node").attr("fill", "rgb(57, 58, 61)");
      gSliderNode.selectAll(".slider-node-shadow").attr("visibility", "hidden");
      select("g.switch-time-cursor").attr("visibility", "hidden");
      select("g.switch-time-cursor")
        .select("text")
        .attr("visibility", "hidden");
      selectAll("rect.bar1").attr("fill", "rgb(57, 58, 61)");
    }
  });

  function dragStart(event) {
    selectAll("circle.slider-node-shadow").attr("visibility", "hidden");
    select(this)
      .selectAll("circle.slider-node-shadow")
      .attr("visibility", "visible");
  }
  function dragging(event) {
    const xPosition = event.x;
    if (0 <= event.x && event.x <= innerWidth) {
      const yearValue = Math.round(yearScale.invert(xPosition));
      select(this).attr("transform", `translate(${xPosition},${0})`);
      dataLabel.text(yearValue);

      //update curve
      calculator.chartDiscountFactorUpdate("switchingTime", yearValue);
      //update output
      calculator.outputUpdate({ switchingTime: yearValue });
    }
  }
}
