import { select, selectAll, scaleLinear, drag } from "d3";
import { layoutFactorBlock } from "./layout-factor-block";
import { slider } from "./slider";

const innerWidth = 570;
const yearScale = scaleLinear().domain([1, 100]).range([0, innerWidth]);

export function sliderProjectDuration() {
  const { containerSelectors, defaultSetting } = this;

  const calculator = this;
  const container = select(containerSelectors.projectDuration);

  //layout
  const { dataLabel, gInner, svg } = layoutFactorBlock(container, {
    heading: "PROJECT DURATION",
    text: "How long does a single temporary project last?",
    unit: "years",
    type: "slider",
    switch: undefined,
  });

  //dataLabel
  dataLabel.text(defaultSetting.projectDuration);

  //slide
  const gSliderNode = slider(
    gInner,
    yearScale(defaultSetting.projectDuration),
    innerWidth
  );

  gSliderNode.call(
    drag()
      .on("start", function (event) {
        selectAll("circle.slider-node-shadow").attr("visibility", "hidden");
        select(this)
          .selectAll("circle.slider-node-shadow")
          .attr("visibility", "visible");
      })
      .on("drag", function (event) {
        const xPosition = event.x;
        if (0 <= event.x && event.x <= innerWidth) {
          const yearValue = Math.round(yearScale.invert(xPosition));
          select(this).attr("transform", `translate(${xPosition},${0})`);
          dataLabel.text(yearValue);

          calculator.chartDiscountFactorUpdate("projectDuration", yearValue);
          calculator.outputUpdate({ projectDuration: yearValue });
        }
      })
  );
}
