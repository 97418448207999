import { select, scaleLinear, line } from "d3";

const innerWidth = 1475;
const innerHeight = 55;
const margin = {
  top: 10,
  bottom: 110,
  left: 0,
  right: 30,
};
const width = innerWidth + margin.left + margin.right;
const height = innerHeight + margin.top + margin.bottom;
const barHeight = 40;
//scale
const yearScale = scaleLinear().domain([0, 100]).range([0, innerWidth]);
const factorScale = scaleLinear().domain([0, 1]).range([innerHeight, 0]);

var gCurve, gBar, gCursor;
var clipPath0, clipPath1;

export function chartDiscountFactor() {
  const { containerSelectors, defaultSetting } = this;
  const container = select(containerSelectors.discountFactor);

  const svg = container
    .append("svg")
    .attr("width", "100%")
    .attr("viewBox", [0, 0, width, height]);

  gCurve = svg
    .append("g")
    .attr("class", "curve-innerArea")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  const gYAxis = svg
    .append("g")
    .attr("class", "curve-yAxis")
    .attr(
      "transform",
      `translate(${margin.left + innerWidth + 10},${margin.top})`
    );

  const gXAxis = svg
    .append("g")
    .attr("class", "curve-xAxis")
    .attr("transform", `translate(${margin.left},${margin.top + innerHeight})`);

  gBar = svg
    .append("g")
    .attr(
      "transform",
      `translate(${margin.left},${margin.top + innerHeight + 15})`
    );

  gCursor = svg.append("g").attr("class", "switch-time-cursor");

  gYAxis
    .append("line")
    .attr("stroke", "#EBEBEC")
    .attr("stroke-width", 1)
    .attr("fill", "none")
    .attr("y1", (d) => factorScale(0))
    .attr("y2", (d) => factorScale(1));

  gYAxis
    .selectAll("text")
    .data(factorScale.domain())
    .join("text")
    .attr("dominant-baseline", "middle")
    .attr("text-anchor", "start")
    .attr("fill", "rgb(235, 235, 236)")
    .attr("font-size", 16)
    .attr("x", 10)
    .attr("y", (d) => factorScale(d))
    .text((d) => d);

  gYAxis
    .append("text")
    .attr("dominant-baseline", "middle")
    .attr("text-anchor", "end")
    .attr("fill", "rgb(235, 235, 236)")
    .attr("font-size", 16)
    .attr("x", 10)
    .attr("x", -10)
    .attr("y", factorScale.range()[1])
    .text("DISCOUNT FACTOR");

  gXAxis
    .append("line")
    .attr("stroke", "#EBEBEC")
    .attr("stroke-width", 1)
    .attr("fill", "none")
    .attr("x1", yearScale.range()[0])
    .attr("x2", yearScale.range()[1]);

  gXAxis
    .selectAll("text")
    .data(yearScale.domain())
    .join("text")
    .attr("dominant-baseline", "hanging")
    .attr("text-anchor", (d, i) => (i == 0 ? "start" : "end"))
    .attr("fill", "rgb(57, 58, 61)")
    .attr("font-size", 16)
    .attr("x", (d) => yearScale(d))
    .attr("y", 15 + barHeight + 8)
    .text((d) => d + " years");

  gXAxis
    .append("line")
    .attr("stroke", "rgb(57, 58, 61)")
    .attr("stroke-width", 1)
    .attr("fill", "none")
    .attr("x1", yearScale.range()[0])
    .attr("x2", yearScale.range()[1])
    .attr("y1", 15 + barHeight + 47)
    .attr("y2", 15 + barHeight + 47);

  clipPath0 = getClipPath(gBar, "bar0", 0, 0, 0, 0);
  clipPath1 = getClipPath(gBar, "bar1", 0, 0, 0, 0);

  gCursor
    .append("line")
    .attr("stroke", "rgb(229, 135, 182)")
    .attr("stroke-width", 5)
    .attr("fill", "none")
    .attr("y1", 0)
    .attr("y2", innerHeight + 15 + barHeight);

  gCursor
    .append("text")
    .attr("dominant-baseline", "hanging")
    .attr("text-anchor", "start")
    .attr("fill", " rgb(229, 135, 182)")
    .attr("font-size", 16)
    .attr("x", -2.5)
    .attr("y", innerHeight + 15 + barHeight + 8)
    .text("SWITCHING TIME");

  this.chartDiscountFactorUpdate("discountRate", defaultSetting.discountRate);
  this.chartDiscountFactorUpdate("switchingTime", defaultSetting.switchingTime);
  this.chartDiscountFactorUpdate(
    "projectDuration",
    defaultSetting.projectDuration
  );
}

export function chartDiscountFactorUpdate(name, value) {
  if (name == "discountRate") {
    curveUpdate(value);
  }
  if (name == "projectDuration") {
    barUpdate(value);
  }
  if (name == "switchingTime") {
    cursorUpdate(value);
  }
}

function curveUpdate(discountRate) {
  const curvePts = [...Array(101).keys()].map((d) => {
    return {
      year: d,
      factor:
        ((discountRate / 0.1) * Math.pow(d, 2)) / 10000 -
        ((discountRate / 0.1) * d) / 50 +
        1,
    };
  });

  const lineGenerator = line()
    .x((d) => yearScale(d.year))
    .y((d) => factorScale(d.factor));

  const curve = gCurve.selectAll("path.curve").data(["curve"]);

  curve
    .enter()
    .append("path")
    .attr("class", "curve")
    .attr("stroke", "rgb(235, 235, 236)")
    .attr("stroke-width", 3)
    .attr("fill", "none")
    .attr("d", lineGenerator(curvePts));

  curve.transition().attr("d", lineGenerator(curvePts));

  curve.exit().remove();
}

function barUpdate(projectDuration) {
  const data = [
    ...Array(Math.ceil(yearScale.domain()[1] / projectDuration)).keys(),
  ].map((d) => d * projectDuration);

  const bar0 = gBar.selectAll("rect.bar0").data(data);

  bar0
    .enter()
    .append("rect")
    .attr("class", "bar0")
    .attr("fill", "rgb(57, 58, 61)")
    .attr("stroke", "none")
    .attr("x", (d) => yearScale(d))
    .attr("width", yearScale(projectDuration) - 2)
    .attr("height", barHeight)
    .attr("clip-path", clipPath0.url);

  bar0
    .attr("x", (d) => yearScale(d))
    .attr("width", yearScale(projectDuration) - 2);

  bar0.exit().remove();

  const bar1 = gBar.selectAll("rect.bar1").data(data);

  bar1
    .enter()
    .append("rect")
    .attr("class", "bar1")
    .attr("fill", " rgb(107,72,93)")
    .attr("stroke", "none")
    .attr("x", (d) => yearScale(d))
    .attr("width", yearScale(projectDuration) - 2)
    .attr("height", barHeight)
    .attr("clip-path", clipPath1.url);

  bar1
    .attr("x", (d) => yearScale(d))
    .attr("width", yearScale(projectDuration) - 2);

  bar1.exit().remove();
}

function cursorUpdate(switchingTime) {
  const xPosition = yearScale(switchingTime);
  gCursor.attr(
    "transform",
    `translate(${margin.left + xPosition},${margin.top})`
  );

  clipPath0.rect
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", xPosition)
    .attr("height", barHeight);

  clipPath1.rect
    .attr("x", xPosition)
    .attr("y", 0)
    .attr("width", yearScale.range()[1] - xPosition)
    .attr("height", barHeight);

  gCursor
    .select("text")
    .attr(
      "visibility",
      xPosition <= 50 || xPosition >= innerWidth - 200 ? "hidden" : "visible"
    );
}

function getClipPath(container, id, x, y, w, h) {
  const rect = container
    .append("defs")
    .append("clipPath")
    .attr("id", id)
    .append("rect")
    .attr("x", x)
    .attr("y", y)
    .attr("width", w)
    .attr("height", h);
  return { rect: rect, url: "url(#" + id + ")" };
}
