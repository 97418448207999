import { chartDiscountFactor,chartDiscountFactorUpdate } from "./chart-discount-factor";
import { sliderProjectDuration } from "./slider-project-duration";
import { sliderSwitchingTime } from "./slider-switching-time";
import { sliderDiscountRate } from "./slider-discount-rate";
import { sliderProjectRisk } from "./slider-project-risk";
import { chartTemporaryCost } from "./chart-temporary-cost";
import { chartPermanentCost } from "./chart-permanent-cost";
import { outputInit, outputUpdate } from "./output";
export class Calculator {
  constructor(containerSelectors) {
    this.defaultSetting = {
      projectDuration: 10,
      switchingTime: 50,
      discountRate: 0.03,
      projectRisk: 0.1,
      temporaryCost: 20,
      permanentCost: 500,
    };

    this.containerSelectors = containerSelectors;

    //
    this.chartDiscountFactor();
    this.sliderProjectDuration();
    this.sliderSwitchingTime();
    this.sliderDiscountRate();
    this.sliderProjectRisk();
    this.chartTemporaryCost();
    this.chartPermanentCost();
    this.outputInit();
  }
}

Object.assign(Calculator.prototype, {
  chartDiscountFactor,
  chartDiscountFactorUpdate,
  sliderProjectDuration,
  sliderSwitchingTime,
  sliderDiscountRate,
  sliderProjectRisk,
  chartTemporaryCost,
  chartPermanentCost,
  outputInit,
  outputUpdate,
});