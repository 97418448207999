import { select, format } from "d3";
var data = {};

const width = 400;
const height = 300;

var gOutput;
export function outputInit() {
  const { containerSelectors, defaultSetting } = this;
  const container = select(containerSelectors.output);

  const svg = container
    .append("svg")
    .attr("width", "100%")
    .attr("viewBox", [0, 0, width, height])
    .attr("overflow", "visible");

  svg
    .selectAll("text")
    .data([
      "If a temporary project now costs...",
      "you actually need to budget...",
      "for permanent carbon removal.",
    ])
    .join("text")
    .attr("dominant-baseline", "hanging")
    .attr("text-anchor", "start")
    .attr("fill", "white")
    .attr("font-size", 20)
    .attr("y", (d, i) => i * 120)
    .text((d) => d);

  gOutput = svg
    .selectAll("g.output")
    .data([0, 1])
    .join("g")
    .attr("transform", (d, i) => `translate(${0},${35 + i * 115})`);

  gOutput
    .append("text")
    .attr("class", (d, i) => "output" + i)
    .attr("dominant-baseline", "middle")
    .attr("text-anchor", "start")
    .attr("fill", "#E587B6")
    .attr("font-size", 66)
    .attr("y", (d, i) => 40 + i * 10);

  gOutput
    .append("text")
    .attr("class", "unit")
    .attr("dominant-baseline", "auto")
    .attr("text-anchor", "start")
    .attr("fill", "#808080")
    .attr("font-size", 22)
    .attr("x", 170)
    .attr("y", (d, i) => 55 + i * 10)
    .text("per tCO₂");

  this.outputUpdate(defaultSetting);
}

export function outputUpdate(valueUpdate) {
  Object.keys(valueUpdate).forEach((key) => {
    data[key] = valueUpdate[key];
  });

  const output0 = calculate0(data);
  const output1 = calculate0(data);

  gOutput.selectAll("text.output0").text(format("$0,.0f")(output0));
  gOutput.selectAll("text.output1").text(format("$0,.0f")(output1));
}

function calculate0(data) {
  const {
    discountRate,
    permanentCost,
    projectDuration,
    projectRisk,
    switchingTime,
    temporaryCost,
  } = data;

  return (
    discountRate * 100 +
    permanentCost +
    projectDuration +
    projectRisk * 100 +
    switchingTime +
    temporaryCost
  );
}
