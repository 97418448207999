export function toggleSwitch(svg, props) {
  const selectedIndex = props.selectedIndex;

  const toggle = svg.selectAll("g.toggle-switch").data(["switch"]);

  const toggleEnter = toggle
    .enter()
    .append("g")
    .attr("class", "toggle-switch")
    .attr("transform", `translate(${155 + 545},${30 - 115})`);

  toggleEnter
    .append("rect")
    .attr("fill", props.backgroundColor[selectedIndex])
    .attr("stroke", "none")
    .attr("x", -50 / 2)
    .attr("y", -20 / 2)
    .attr("width", 50)
    .attr("height", 20)
    .attr("rx", 10);

  toggleEnter
    .append("circle")
    .attr("fill", props.color[selectedIndex])
    .attr("stroke", "none")
    .attr("cx", selectedIndex == 0 ? -13 : 13)
    .attr("r", 7);

  toggleEnter
    .append("text")
    .attr("dominant-baseline", "hanging")
    .attr("text-anchor", "middle")
    .attr("fill", props.backgroundColor[selectedIndex])
    .attr("font-size", 16)
    .attr("y", 20)
    .text(props.value[selectedIndex]);

  const toggleUpdate = toggle
    .transition()
    .attr("transform", `translate(${155 + 545},${30 - 115})`);

  toggleUpdate
    .select("circle")
    .attr("fill", props.color[selectedIndex])
    .attr("cx", selectedIndex == 0 ? -13 : 13);

  toggleUpdate
    .select("text")
    .attr("fill", props.backgroundColor[selectedIndex])
    .transition()
    .text(props.value[selectedIndex]);

  toggleUpdate
    .select("rect")
    .attr("fill", props.backgroundColor[selectedIndex]);
  
  toggle.exit().remove();
}
