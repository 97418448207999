import { Calculator } from "./calculator/calculator";

const calculator = new Calculator({
  discountFactor: ".discountFactor",
  projectDuration: ".projectDuration",
  switchingTime: ".switchingTime",
  discountRate: ".discountRate",
  projectRisk: ".projectRisk",
  temporaryCost: ".temporaryCost",
  permanentCost: ".permanentCost",
  output: ".output",
});
