import { select, scaleLinear, format, line, drag, mean } from "d3";
import { layoutFactorBlock } from "./layout-factor-block";

import { toggleSwitch } from "./toggle-switch";
const innerWidth = 523;
const innerHeight = 160;
const yearScale = scaleLinear().domain([0, 100]).range([0, innerWidth]);
const costScale = scaleLinear().domain([0, 100]).range([innerHeight, 0]);

export function chartTemporaryCost() {
  const { containerSelectors, defaultSetting } = this;
    const calculator = this;
  const container = select(containerSelectors.temporaryCost);
  const switchProps = {
    value: ["VARY", "FIXED"],
    backgroundColor: ["rgba(229,135,182,0.55)", "#393a3d"],
    color: ["#e587b6", "#808080"],
    selectedIndex: 1,
  };

  const { dataLabel, gInner, svg } = layoutFactorBlock(container, {
    heading: "TEMPORARY COST",
    text: "What is the cost of temporary carbon removal over time?",
    unit: "$/tCO₂",
    type: "chart",
  });

  toggleSwitch(svg, switchProps);

  const yTick = [0, 20, 40, 60, 80, 100];

  gInner
    .append("rect")
    .attr("fill", "#393a3d")
    .attr("stroke", "none")
    .attr("width", innerWidth)
    .attr("height", innerHeight);

  gInner
    .selectAll("line.grid")
    .data(yTick.slice(1, yTick.length - 1))
    .join("line")
    .attr("class", "grid")
    .attr("stroke", "#1B1E23")
    .attr("stroke-width", 2)
    .attr("x1", 0)
    .attr("x2", innerWidth)
    .attr("y1", (d) => costScale(d))
    .attr("y2", (d) => costScale(d));

  gInner
    .selectAll("text.ytick")
    .data(yTick.slice(1, yTick.length - 1))
    .join("text")
    .attr("class", "ytick")
    .attr("dominant-baseline", "middle")
    .attr("text-anchor", "start")
    .attr("fill", "#393a3d")
    .attr("font-size", 16)
    .attr("x", innerWidth + 15)
    .attr("y", (d) => costScale(d) + 3)
    .text((d) => format("$0,.0f")(d));

  gInner
    .selectAll("text.xtick")
    .data(yearScale.domain())
    .join("text")
    .attr("class", "xtick")
    .attr("dominant-baseline", "hanging")
    .attr("text-anchor", (d, i) => (i == 0 ? "start" : "end"))
    .attr("fill", "#393a3d")
    .attr("font-size", 16)
    .attr("x", (d) => yearScale(d))
    .attr("y", innerHeight + 10)
    .text((d) => d + " years");

  const data = [...Array(6).keys()].map((d) => {
    return {
      year: (d * yearScale.domain()[1]) / 5,
      cost: defaultSetting.temporaryCost,
    };
  });

  initState();
  svg.select("g.toggle-switch").on("click", function (event) {
    switchProps.selectedIndex = switchProps.selectedIndex == 0 ? 1 : 0;
    toggleSwitch(svg, switchProps);

    initState();
  });

  function initState() {
    dataLabel.text(format("$0,.0f")(mean(data, (d) => d.cost)));
    if (switchProps.value[switchProps.selectedIndex] == "FIXED") {
      const avgCost = mean(data, (d) => d.cost);
      data.forEach((d) => {
        d.cost = avgCost;
      });
      markFixedMode();

      gInner
        .selectAll("circle.mark")
        .call(
          drag().on("start", startDrag).on("drag", dragging).on("end", endDrag)
        );

      function startDrag(event) {
        gInner.selectAll("text.mark").attr("visibility", "visible");
      }
      function dragging(event) {
        if (0 <= event.y && event.y <= innerHeight) {
          const yValue = costScale.invert(event.y);

          data.forEach((d) => (d.cost = yValue));
          markFixedMode();
          gInner
            .selectAll("text.mark")
            .attr("visibility", "visible")
            .attr("y", event.y - 15)
            .text(format("$0,.0f")(yValue));

          dataLabel.text(format("$0,.0f")(yValue));

          calculator.outputUpdate({ permanentCost: yValue });
        }
      }
      function endDrag(event) {
        gInner.selectAll("text.mark").attr("visibility", "hidden");
      }
    } else {
      markVaryMode();

      gInner
        .selectAll("circle.mark")
        .call(
          drag().on("start", startDrag).on("drag", dragging).on("end", endDrag)
        );

      function startDrag(event, dd) {
        gInner
          .selectAll("text.mark")
          .filter((d) => d.year == dd.year)
          .attr("visibility", "visible");
      }
      function dragging(event, dd) {
        if (0 <= event.y && event.y <= innerHeight) {
          const yValue = costScale.invert(event.y);

          //data
          data.forEach((d) => {
            if (d.year == dd.year) {
              d.cost = yValue;
            }
          });
          markVaryMode();
          gInner
            .selectAll("text.mark")
            .filter((d) => d.year == dd.year)
            .attr("visibility", "visible");

          dataLabel.text(format("$0,.0f")(mean(data, (d) => d.cost)));
          calculator.outputUpdate({
            permanentCost: mean(data, (d) => d.cost),
          });
        }
      }
      function endDrag(event) {
        gInner.selectAll("text.mark").attr("visibility", "hidden");
      }
    }
  }
  function markFixedMode() {
    gInner
      .selectAll("path.mark")
      .data(["path"])
      .join("path")
      .attr("class", "mark")
      .attr("stroke", "#e587b6")
      .attr("stroke-width", 2)
      .attr("fill", "none")
      .attr(
        "d",
        line()
          .x((d) => yearScale(d.year))
          .y((d) => costScale(d.cost))(data)
      );

    gInner
      .selectAll("circle.mark")
      .data(["path"])
      .join("circle")
      .attr("class", "mark")
      .attr("stroke", "none")
      .attr("fill", "#e587b6")
      .attr("cx", innerWidth / 2)
      .attr("cy", costScale(mean(data, (d) => d.cost)))
      .attr("r", 8);

    gInner
      .selectAll("text.mark")
      .data([0])
      .join("text")
      .attr("class", "mark")
      .attr("dominant-baseline", "auto")
      .attr("text-anchor", "middle")
      .attr("fill", "#e587b6")
      .attr("font-size", 14)
      .attr("x", innerWidth / 2)
      .attr("y", costScale(mean(data, (d) => d.cost)) - 15)
      .text(format("$0,.0f")(mean(data, (d) => d.cost)))
      .attr("visibility", "hidden");
  }

  function markVaryMode() {
    gInner
      .selectAll("path.mark")
      .data(["path"])
      .join("path")
      .attr("class", "mark")
      .attr("stroke", "#e587b6")
      .attr("stroke-width", 2)
      .attr("fill", "none")
      .attr(
        "d",
        line()
          .x((d) => yearScale(d.year))
          .y((d) => costScale(d.cost))(data)
      );

    gInner
      .selectAll("circle.mark")
      .data(data)
      .join("circle")
      .attr("class", "mark")
      .attr("stroke", "none")
      .attr("fill", "#e587b6")
      .attr("cx", (d) => yearScale(d.year))
      .attr("cy", (d) => costScale(d.cost))
      .attr("r", 8);

    gInner
      .selectAll("text.mark")
      .data(data)
      .join("text")
      .attr("class", "mark")
      .attr("dominant-baseline", "auto")
      .attr("text-anchor", "middle")
      .attr("fill", "#e587b6")
      .attr("font-size", 14)
      .attr("x", (d) => yearScale(d.year))
      .attr("y", (d) => costScale(d.cost) - 15)
      .text((d) => format("$0,.0f")(d.cost))
      .attr("visibility", "hidden");
  }
}
