import { select,selectAll, scaleLinear, format, drag } from "d3";
import { layoutFactorBlock } from "./layout-factor-block";
import { slider } from "./slider";

const innerWidth = 570;
const riskScale = scaleLinear().domain([0, 0.1]).range([0, innerWidth]);

export function sliderProjectRisk() {
  const { containerSelectors, defaultSetting } = this;
  const calculator = this;
  const container = select(containerSelectors.projectRisk);

  const { dataLabel, gInner, svg } = layoutFactorBlock(container, {
    heading: "PROJECT RISK",
    text: "What is the risk each year that a temporary project fails? Project failure triggers paying for a new project.",
    unit: "per year",
    type: "slider",
    switch: undefined,
  });

  //
  dataLabel.text(format(".1%")(defaultSetting.projectRisk));
  
  //
  const gSliderNode = slider(
    gInner,
    riskScale(defaultSetting.projectRisk),
    innerWidth
  );

  gSliderNode.call(drag().on("start", startDrag).on("drag", dragging));

  function startDrag(event) {
    selectAll("circle.slider-node-shadow").attr("visibility", "hidden");
    select(this)
      .selectAll("circle.slider-node-shadow")
      .attr("visibility", "visible");
  }

  function dragging(event) {
    const xPosition = event.x;
    if (0 <= event.x && event.x <= innerWidth) {
      const xValue = riskScale.invert(xPosition);
      select(this).attr("transform", `translate(${xPosition},${0})`);

      dataLabel.text(format(".1%")(xValue));

       calculator.outputUpdate({ projectRisk: xValue });
    }
  }
}
