import { select, selectAll, scaleLinear, format, drag } from "d3";
import { layoutFactorBlock } from "./layout-factor-block";
import { slider } from "./slider";

const innerWidth = 570;
const rateScale = scaleLinear().domain([0, 0.1]).range([0, innerWidth]);

export function sliderDiscountRate() {
  const { containerSelectors, defaultSetting } = this;
  const container = select(containerSelectors.discountRate);

  const { dataLabel, gInner, svg } = layoutFactorBlock(container, {
    heading: "DISCOUNT RATE",
    text: "What is the rate for discounting future costs? Higher discount rates reduce total costs.",
    unit: "percent",
    type: "slider",
    switch: undefined,
  });

  dataLabel.text(format(".1%")(defaultSetting.discountRate));
  const gSliderNode = slider(
    gInner,
    rateScale(defaultSetting.discountRate),
    innerWidth
  );

  const calculator = this;
  gSliderNode.call(drag().on("start", startDrag).on("drag", dragging));

  function startDrag(event) {
    selectAll("circle.slider-node-shadow").attr("visibility", "hidden");
    select(this)
      .selectAll("circle.slider-node-shadow")
      .attr("visibility", "visible");
  }

  function dragging(event) {
    const xPosition = event.x;
    if (0 <= event.x && event.x <= innerWidth) {
      const xValue = rateScale.invert(xPosition);
      select(this).attr("transform", `translate(${xPosition},${0})`);

      dataLabel.text(format(".1%")(xValue));
      calculator.chartDiscountFactorUpdate("discountRate", xValue);
      calculator.outputUpdate({ discountRate: xValue });
    }
  }
}
