export function slider(container, defaultPosition, range) {
  container
    .append("line")
    .attr("class", "slider-range")
    .attr("stroke", "#808080")
    .attr("stroke-width", 4)
    .attr("stroke-linecap", "round")
    .attr("x2", range);

  const gSliderNode = container
    .append("g")
    .attr("class", "slider-node")
    .attr("transform", `translate(${defaultPosition},${0})`);

  gSliderNode
    .append("circle")
    .attr("class", "slider-node-shadow")
    .attr("fill", "#808080")
    .attr("stroke", "none")
    .attr("r", 12.5)
    .attr("visibility", "hidden");

  gSliderNode
    .append("circle")
    .attr("class", "slider-node")
    .attr("fill", "#E587B6")
    .attr("stroke", "none")
    .attr("r", 8);
  
  return gSliderNode;
  
}
