const width = 750;


export function layoutFactorBlock(container, param) {
  container
    .append("div")
    .attr("class","factor-block-heading")
    .text(param.heading);
  
  container.append("div").attr("class", "factor-block-text").text(param.text);
  
  const svg = container
    .append("svg")
    .attr("width", "100%")
    .attr("viewBox", [0, 0, width, param.type == "slider" ? 65 : 195])
    .attr("overflow", "visible");
    ;

  //label
  const gLabel = svg.append("g").attr("transform", `translate(${0},${30})`);

  gLabel
    .append("line")
    .attr("stroke", " #ebebec")
    .attr("stroke-width", 1)
    .attr("x2", 108);

  const dataLabel = gLabel
    .append("text")
    .attr("dominant-baseline", "auto")
    .attr("text-anchor", "start")
    .attr("fill", "#e587b6")
    .attr("font-size", 24)
    .attr("y", -12)
    .text("123");
  
  gLabel
    .append("text")
    .attr("dominant-baseline", "hanging")
    .attr("text-anchor", "start")
    .attr("fill", "#808080")
    .attr("font-size", 18)
    .attr("y", 12)
    .text(param.unit);

  //gInner
  var gInner;
  if (param.type == "slider") {
    gInner = svg.append("g").attr("transform", `translate(${155},${30})`);
  }
  if (param.type == "chart") {
    gInner = svg.append("g").attr("transform", `translate(${155},${0})`);
  }

  return { dataLabel, gInner, svg};
}
